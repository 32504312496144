/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { SxProps } from '@mui/material';

export const wacoalHeaderMiniCartLinkSX = (isBtemptd: boolean): SxProps => ({
	p: 0,
	...(isBtemptd
		? { mr: { xs: '11px', md: '18px', lg: '36px' }, mt: { xs: '1px', lg: 0 } }
		: { mr: { xs: '14px', md: '18px', lg: '20px' } }),
	mb: { xs: isBtemptd ? '1px' : '3px', md: isBtemptd ? '1px' : 0, lg: 0 },
	ml: { md: '6px', lg: 0 },
});
