/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { Img } from '@/components/blocks/MaterialImage';
import { ContentRecommendation } from '@/components/content/ContentRecommendation';
import { headerSearchPopperSX } from '@/components/content/Header/styles/search/searchPopper';
import { wacoalHeaderSearchSX } from '@/components/content/Header/styles/wacoalSearch';
import { useScene7ImgUrl } from '@/data/Content/Scene7ImgUrl';
import { useSearchNavigation } from '@/data/Content/WacoalSearchNavigation';
import { useLocalization } from '@/data/Localization';
import { ContentContext } from '@/data/context/content';
import { ID } from '@/data/types/Basic';
import {
	Autocomplete,
	AutocompleteRenderOptionState,
	Box,
	IconButton,
	InputAdornment,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import { FC, HTMLAttributes, useContext, useMemo } from 'react';

type Option = {
	label: string;
	href?: string;
	identifier: string;
	partNumber?: string;
	thumbnailRaw?: string;
	type: string;
};

export const WacoalHeaderSearch: FC<{
	mobile: boolean;
	isPageScrolled?: boolean;
	isHomepage?: boolean;
	isWacoal: boolean;
	isBtemptd: boolean;
	id: ID;
}> = ({ mobile, isPageScrolled = false, isHomepage = false, isWacoal, isBtemptd, id }) => {
	// const { searchValue, suggest, onInputChange, onSubmit } = useSearchNavigation();
	const { searchValue, suggest, onInputChange, onSubmit, inputValue } = useContext(
		ContentContext
	) as ReturnType<typeof useSearchNavigation>;
	const SearchNLS = useLocalization('SearchBar');
	const uniqueId = `type-ahead-site-search-${mobile ? 'mobile' : 'desktop'}`;
	const { getThumbnailUrl } = useScene7ImgUrl();
	const options = useMemo<Option[]>(() => {
		const keywordSuggestions =
			suggest?.keywordSuggestions?.map((keyword) => ({
				identifier: SearchNLS.SuggestedKeywords.t(),
				label: keyword,
				type: 'keyword',
			})) || [];

		const productSuggestions =
			suggest?.productSuggestions?.map((product) => ({
				identifier: SearchNLS.SuggestedProducts.t(),
				label: product.name,
				href: product.href,
				partNumber: product.partNumber,
				thumbnailRaw: product.thumbnailRaw,
				type: 'product',
			})) || [];

		const otherSuggestions =
			suggest?.otherSuggestions
				?.map(({ identifier, entry }) =>
					identifier && entry
						? entry?.map(({ label, href }) => ({
								label,
								href,
								identifier,
								type: 'other',
						  })) || []
						: []
				)
				.flat(1) || [];

		return [...keywordSuggestions, ...productSuggestions, ...otherSuggestions];
	}, [SearchNLS.SuggestedKeywords, SearchNLS.SuggestedProducts, suggest]);

	const renderOption = (
		props: HTMLAttributes<HTMLLIElement>,
		option: Option,
		state: AutocompleteRenderOptionState
	) => {
		const highlightSearchTerm = (label: string, searchTerm: string) =>
			label.replace(new RegExp('(^|)(' + searchTerm + ')(|$)', 'ig'), '$1<b>$2</b>$3');

		return (
			<li
				{...props}
				className={`${props.className} ${option.type === 'product' ? 'product' : 'text'}`}
			>
				{option.type === 'product' ? (
					<Stack direction="row">
						<Box sx={{ width: '33.333%' }}>
							<Img
								alt=""
								src={getThumbnailUrl(option.thumbnailRaw ? option.thumbnailRaw : '')}
								sx={{ border: '1px solid #D9D9D9' }}
							/>
						</Box>
						<Stack sx={{ width: '66.666%', paddingLeft: '5px' }}>
							<Typography
								sx={{ fontSize: '13px' }}
								dangerouslySetInnerHTML={{
									__html: highlightSearchTerm(option.label, state.inputValue),
								}}
							/>
							<Typography sx={{ fontSize: '12px', color: '#737373' }}>
								{option.partNumber}
							</Typography>
						</Stack>
					</Stack>
				) : (
					<Box
						dangerouslySetInnerHTML={{
							__html: highlightSearchTerm(option.label, state.inputValue),
						}}
					/>
				)}
			</li>
		);
	};

	return (
		<Autocomplete
			freeSolo
			disableClearable
			onChange={(_, item) => onSubmit(typeof item === 'string' ? { label: item } : item)}
			inputValue={inputValue}
			onInputChange={onInputChange}
			groupBy={({ identifier }) => identifier}
			options={options}
			renderOption={renderOption}
			sx={wacoalHeaderSearchSX({ isPageScrolled, isHomepage, isWacoal, isBtemptd })}
			id={uniqueId}
			componentsProps={{
				paper: {
					sx: headerSearchPopperSX,
				},
			}}
			renderInput={({ inputProps, ...params }) => (
				<TextField
					inputProps={{ ...inputProps, 'data-testid': uniqueId }}
					{...params}
					InputProps={{
						...params.InputProps,
						placeholder: isBtemptd ? SearchNLS.SearchField.t() : undefined,
						slotProps: {
							input: {
								role: undefined,
								'aria-expanded': undefined,
								'aria-label': SearchNLS.SearchField.t(),
							},
						},
						size: 'small',
						type: 'search',
						onKeyDown: (e) => {
							if (e.code === 'Enter' && e.currentTarget.value) {
								onSubmit({ label: e.currentTarget.value });
							}
						},
						endAdornment:
							!mobile && isWacoal ? (
								<InputAdornment position="end">
									<IconButton
										aria-label={SearchNLS.SearchField.t()}
										onClick={() => onSubmit({ label: searchValue })}
										edge="end"
									>
										<ContentRecommendation
											key="SearchIcon"
											id={id}
											properties={{ emsName: 'SearchIcon' }}
										/>
									</IconButton>
								</InputAdornment>
							) : null,
						startAdornment:
							!mobile && isBtemptd ? (
								<InputAdornment position="start">
									<IconButton
										aria-label={SearchNLS.SearchField.t()}
										onClick={() => onSubmit({ label: searchValue })}
										edge="start"
									>
										<ContentRecommendation
											key="SearchIcon"
											id={id}
											properties={{ emsName: 'SearchIcon' }}
										/>
									</IconButton>
								</InputAdornment>
							) : null,
					}}
				/>
			)}
		/>
	);
};
