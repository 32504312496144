/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { SxProps } from '@mui/material';

export const headerNavBarDropMenuSX: SxProps = {
	maxWidth: 'none',
	boxShadow: 0,
	margin: '0 !important',
	backgroundColor: '#F9F9F9',
	padding: { lg: '20px 7% 30px' },
	borderBottom: '1px solid #d2d2d2',
	borderRadius: '0 0 4px 4px',
	color: '#252525',
	letterSpacing: '0.25px',
};
