import { SxProps } from '@mui/material';

export const headerSearchPopperSX: SxProps = () => ({
	borderRadius: '0',
	boxShadow: 'none',

	'.MuiAutocomplete-listbox': {
		padding: 0,
		maxHeight: { xs: 'calc(100vh - 156px)', lg: 'calc(90vh - 120px)' },
		border: '1px solid #c9cece',
	},

	'.MuiAutocomplete-groupLabel': {
		textTransform: 'uppercase',
		fontSize: '12px',
		fontWeight: 500,
		lineHeight: '16px',
		letterSpacing: '0.5px',
		color: '#252525',
		backgroundColor: '#EFEFEF',
		padding: '12px 16px',
	},

	'.MuiAutocomplete-groupUl': {
		backgroundColor: '#F9F9F9',
		padding: '0 16px',
	},

	'.MuiAutocomplete-option': {
		fontSize: '13px',
		color: '#252525',
		lineHeight: '22px',
		borderBottom: 'solid 2px #D8D8D8',
		padding: '8px 0 !important',
		'&:last-child': {
			borderBottom: 0,
		},
		'&:hover': {
			backgroundColor: '#d9e9f7',
		},
		'&.text:hover': {
			textDecoration: 'underline',
		},
		'&.product:hover > div > div:nth-of-type(2) > p:first-of-type': {
			textDecoration: 'underline',
		},
	},
});
