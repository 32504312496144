/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { useJSPStoreUrl } from '@/data/Content/JSPStoreUrl';
import { useExtraRequestParameters } from '@/data/Content/_ExtraRequestParameters';
import { useNextRouter } from '@/data/Content/_NextRouter';
import { useLocalization } from '@/data/Localization';
import { getContractIdParamFromContext, useSettings } from '@/data/Settings';
import { useUser } from '@/data/User';
import { TYPE_AHEAD_DELAY } from '@/data/config/TYPE_AHEAD_DELAY';
import {
	SKU_SUGGESTION_PAGE_NUMBER,
	SKU_SUGGESTION_PAGE_SIZE,
} from '@/data/constants/siteContentSuggestion';
import { SuggestionBySearchTermQuery } from '@/data/types/SiteContentSuggestion';
import { getClientSideCommon } from '@/data/utils/getClientSideCommon';
import { getProductListQueryParameters } from '@/data/utils/getProductListQueryParameters';
import { laggyMiddleWare } from '@/data/utils/laggyMiddleWare';
import { querySiteContentResource } from 'integration/generated/query';
import { SiteContentResource } from 'integration/generated/query/SiteContentResource';
import {
	CommonSuggestions,
	KeywordSuggestion,
	ProductSuggestion,
} from 'integration/generated/query/data-contracts';
import { debounce, isEmpty } from 'lodash';
import { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';

type Props = Parameters<SiteContentResource['findSuggestions']>;

type SuggestionView = {
	identifier?: string; // e.g. Category, Brand, etc.
	entry: EntryView[];
};
type EntryView = {
	label: string;
	href?: string;
};
type ProductEntry = {
	name: string;
	partNumber: string;
	thumbnailRaw?: string;
	href: string;
};
type SuggestionList = {
	keywordSuggestions: string[] | undefined;
	productSuggestions: ProductEntry[] | undefined;
	otherSuggestions: SuggestionView[];
};

// Todo: highlight the search term within the keyword
const keywordDataMap = (data?: KeywordSuggestion): string[] | undefined => {
	if (data?.suggestionView?.length) {
		// OOB suggestions not typed correctly
		const keywords = data.suggestionView
			.at(0)
			?.entry?.map((whatever: any) => whatever?.term as string);
		if (keywords?.length) {
			return keywords;
		}
	}
};
const productDataMap = (data?: ProductSuggestion): ProductEntry[] | undefined => {
	if (data?.suggestionView?.length) {
		// OOB product suggestions not typed correctly
		const products = data.suggestionView.at(0)?.entry?.map((whatever: any) => ({
			name: whatever.name,
			partNumber: whatever.partNumber,
			href: whatever.seo.href,
			thumbnailRaw: whatever.thumbnailRaw,
		}));
		if (products?.length) {
			return products;
		}
	}
};
// include a max of 4 items in each list
const dataMap = (data?: CommonSuggestions): SuggestionView[] =>
	data?.suggestionView
		?.map(({ identifier, entry }) => ({
			identifier,
			entry:
				entry?.slice(0, 4).map(({ fullPath, term, name, seo }: any) => ({
					label: fullPath || term || name,
					...(seo ? seo : {}),
				})) || [],
		}))
		.filter(({ entry }) => entry.length > 0) || [];

const fetcher =
	(pub: boolean) =>
	async ({
		storeId,
		query,
		params = {},
	}: {
		storeId: Props[0];
		query: any;
		params: Props[2];
	}): Promise<SuggestionList | void> => {
		try {
			// get keywords from findKeywordSuggestionsByTerm
			const keywordData = await querySiteContentResource(pub).findKeywordSuggestionsByTerm(
				storeId,
				query.term,
				query,
				params
			);
			const keywordList = keywordDataMap(keywordData);

			// get product from partNumberSuggestionFetcher
			const productData = await querySiteContentResource(pub).findProductSuggestionsBySearchTerm(
				storeId,
				query.term,
				{
					pageSize: SKU_SUGGESTION_PAGE_SIZE,
					pageNumber: SKU_SUGGESTION_PAGE_NUMBER,
					...query,
				} as SuggestionBySearchTermQuery,
				params
			);
			const productList = productDataMap(productData);

			// get categories, brands, and articles from findSuggestions
			// JSP does not pass a suggestType, so leaving it blank
			const otherData = await querySiteContentResource(pub).findSuggestions(
				storeId,
				query as Props[1],
				params
			);
			const otherDataList = dataMap(otherData);

			const allSuggestions: SuggestionList = {
				keywordSuggestions: keywordList,
				productSuggestions: productList,
				otherSuggestions: otherDataList,
			};

			return allSuggestions;
		} catch (error) {
			console.log(error);
		}
	};

const EMPTY_SUGGESTIONS = {};
export const useSearchNavigation = () => {
	const { settings } = useSettings();
	const { user } = useUser();
	const SearchLocalization = useLocalization('Routes').Search;
	const router = useNextRouter();
	const { query } = router;
	const params = useExtraRequestParameters();
	const filteredParams = useMemo(() => getProductListQueryParameters(query), [query]);
	const [searchValue, setSearchValue] = useState<string>(
		() => filteredParams.searchTerm?.toString().trim() || ''
	);
	const [inputValue, setInputValue] = useState<string>(
		() => filteredParams.searchTerm?.toString().trim() || ''
	);
	const { storeId, langId } = getClientSideCommon(settings, router);
	const { createJSPStoreUrlFromViewName } = useJSPStoreUrl();

	const { data = EMPTY_SUGGESTIONS } = useSWR(
		storeId && searchValue !== ''
			? [
					{
						storeId,
						query: {
							langId,
							// suggestType: ['Keyword', 'Product', 'Category'],
							term: searchValue,
							...(!user?.isEmployee || user?.onBehalfOf
								? { '_wcf.search.filter.expr': 'facets.7741124012283333342.value.raw:N' }
								: undefined),
							'_wcf.search.internal.filterquery': 'inventories.10001.quantity:[0.1 TO *]',
							...getContractIdParamFromContext(user?.context),
						},
						params: {},
					},
			  ]
			: null,
		async ([props]) => fetcher(true)({ ...props, params }),
		{ use: [laggyMiddleWare] }
	);
	// const suggest = useMemo(() => dataMap(data), [data]);

	const onSubmit = ({ label, href, type }: { label: string; href?: string; type?: string }) => {
		if (label.trim() === '') {
			return;
		}
		if (isEmpty(href)) {
			// redirect to JSP search results page
			const extraJSPSearchParams = `&searchTerm=${encodeURIComponent(
				label
			)}&sType=SimpleSearch&resultCatEntryType=2&showResultsPage=true&searchSource=Q&beginIndex=0&pageSize=48`;
			const url = createJSPStoreUrlFromViewName('SearchDisplay', extraJSPSearchParams);
			window.location.href = url;
		} else {
			if (type === 'product') {
				router.push(
					href
						? { pathname: href }
						: {
								pathname: `/${SearchLocalization.route.t()}`,
								query: { searchTerm: encodeURIComponent(label) },
						  },
					undefined,
					{
						shallow: true,
					}
				);
			} else if (typeof href === 'string') {
				window.location.href = href; // reload page for non-product urls
			}
		}
	};

	/**
	 * @deprecated use onInputChange instead.
	 */
	const onChange = (newValue: string) => setSearchValue(newValue);

	// const onInputChange = debounce((_, value) => setSearchValue(value), TYPE_AHEAD_DELAY);
	const updateSearchValue = debounce((value) => setSearchValue(value), TYPE_AHEAD_DELAY);

	const onInputChange = (_: SyntheticEvent<Element, Event>, value: string) => {
		setInputValue(value);
		updateSearchValue(value);
	};

	const clearSearchTerm = () => {
		setInputValue('');
		updateSearchValue('');
	};

	useEffect(() => {
		setSearchValue(filteredParams.searchTerm?.toString().trim() || '');
	}, [filteredParams.searchTerm]);

	return {
		suggest: data as SuggestionList,
		searchValue,
		/** @deprecated use onInputChange instead. */
		onChange,
		onInputChange,
		onSubmit,
		inputValue,
		clearSearchTerm,
	};
};
