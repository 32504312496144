/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { MobileDrawerMenu } from '@/components/content/Header/parts/MobileDrawerMenu';
import { headerMobileDrawerSX } from '@/components/content/Header/styles/mobileDrawer';
import { useNextRouter } from '@/data/Content/_NextRouter';
import { useNavigation } from '@/data/Navigation';
import { ID } from '@/data/types/Basic';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Drawer, IconButton, Stack } from '@mui/material';
import { FC, useEffect } from 'react';

export const WacoalHeaderMobileDrawer: FC<{
	toggleDrawer: any;
	open: boolean;
	id: ID;
}> = ({ toggleDrawer, open, id }) => {
	const { navigation } = useNavigation();
	const router = useNextRouter();
	useEffect(() => {
		if (!router.asPath) return;
		toggleDrawer(false)();
	}, [router.asPath, toggleDrawer]);
	return (
		<Drawer anchor="left" open={open} onClose={toggleDrawer(false)} sx={headerMobileDrawerSX}>
			<Box role="presentation">
				<Stack justifyContent="flex-end" sx={{ marginTop: 1, marginRight: 1, marginBottom: '6px' }}>
					<IconButton onClick={toggleDrawer(false)} sx={{ marginLeft: 'auto' }}>
						<CloseIcon sx={{ color: '#000' }} />
					</IconButton>
				</Stack>
				<Stack direction="column">
					<MobileDrawerMenu id={id} tree={navigation?.filter(({ url }) => !!url)} />
				</Stack>
			</Box>
		</Drawer>
	);
};
