import { ProgressIndicator } from '@/components/blocks/ProgressIndicator';
import { useContentRecommendation } from '@/data/Content/ContentRecommendation';
import { useContentEvents } from '@/data/Content/_ContentEvents';
import { renderContent } from '@/utils/renderContent';
import { FC, useEffect } from 'react';

export const FreeShipEspot: FC<{ count: number }> = ({ count }) => {
	const { data, loading, refreshEspot } = useContentRecommendation('MiniCartFreeShipEspot');
	const { onContentClick } = useContentEvents();

	useEffect(() => {
		refreshEspot();
	}, [count, refreshEspot]);

	return loading ? (
		<ProgressIndicator />
	) : (
		<>{data?.map((content) => renderContent(content, onContentClick(content), true))}</>
	);
};
