/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { Box, IconButton, useMediaQuery, useTheme } from '@mui/material';
import Image from 'next/image';
import { FC } from 'react';

export const HeaderMobileToggleButton: FC<{
	toggleDrawer: (open?: boolean) => () => void;
	open: boolean;
	isPageScrolled?: boolean;
	isHomepage?: boolean;
}> = ({ toggleDrawer, isPageScrolled = false, isHomepage = false }) => {
	const theme = useTheme();
	const show = useMediaQuery(theme.breakpoints.down('lg'));
	return show ? (
		<Box sx={{ flex: 1 }}>
			<IconButton
				aria-label="open drawer"
				onClick={toggleDrawer()}
				edge="start"
				sx={{
					ml: '12px',
					width: '46px',
					height: '46px',
					color: { xs: 'inherit', lg: isHomepage && !isPageScrolled ? 'white' : 'inherit' },
				}}
			>
				<Image
					src="https://images.wacoal-america.com/is/image/WacoalAmerica/WAC_HAMBURGER_2x_041924?fmt=png-alpha"
					alt=""
					height="17"
					width="29"
				/>
			</IconButton>
		</Box>
	) : null;
};
