/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { WacoalHeaderNavBarDropMenu } from '@/components/content/Header/parts/WacoalNavBarDropMenu';
import { headerNavBarContainerSX } from '@/components/content/Header/styles/navBar/container';
import { useNextRouter } from '@/data/Content/_NextRouter';
import { useNavigation } from '@/data/Navigation';
import { ID } from '@/data/types/Basic';
import { Box, Stack } from '@mui/material';
import { Dispatch, FC, SetStateAction, useEffect, useRef } from 'react';

export const WacoalHeaderNavBar: FC<{
	openMenuId: string | undefined;
	setOpenMenuId: Dispatch<SetStateAction<string | undefined>>;
	isPageScrolled?: boolean;
	isHomepage?: boolean;
	isBtemptd: boolean;
	id: ID;
}> = ({ openMenuId, setOpenMenuId, isPageScrolled = false, isHomepage = false, isBtemptd, id }) => {
	const router = useNextRouter();
	const { navigation } = useNavigation();

	const containerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (!router.asPath) return;
		setOpenMenuId(undefined);
	}, [router.asPath, setOpenMenuId]);

	const handleOnBlur = (e: any) => {
		if (!containerRef?.current?.contains(e?.relatedTarget)) {
			setOpenMenuId(undefined);
		}
	};

	return (
		<Box
			sx={headerNavBarContainerSX}
			component="nav"
			aria-label="Main Menu"
			onBlur={handleOnBlur}
			ref={containerRef}
		>
			<Stack
				component="ul"
				direction="row"
				justifyContent={isBtemptd ? 'center' : 'space-between'}
				width="100%"
				sx={{ margin: { lg: '0 8%', xl: '0 12%' }, listStyle: 'none', p: 0 }}
			>
				{navigation?.map(({ label, categoryId, url, children }) => (
					<WacoalHeaderNavBarDropMenu
						tree={children}
						label={label}
						categoryId={categoryId}
						isPageScrolled={isPageScrolled}
						isHomepage={isHomepage}
						isBtemptd={isBtemptd}
						id={id}
						href={url}
						key={`${label}${url}`}
						isOpen={openMenuId === categoryId}
						openMenuId={openMenuId}
						setOpenMenuId={setOpenMenuId}
					/>
				))}
			</Stack>
		</Box>
	);
};
