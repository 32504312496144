import { SxProps } from '@mui/material';

export const mobileSearchBoxSX: SxProps = () => ({
	display: {
		lg: 'none',
		md: 'block',
		sm: 'block',
		xs: 'block',
	},
	'& .MuiAutocomplete-root': {
		display: {
			lg: 'none',
			md: 'block',
			sm: 'block',
			xs: 'block',
		},
	},
});
