/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { ContentRecommendation } from '@/components/content/ContentRecommendation';
import { MobileAccountDialog } from '@/components/content/Header/parts/WacoalMobileAccountDialog';
import { navItemAccordionContainerSX } from '@/components/content/Header/styles/navBar/navItemAccordionContainer';
import { useLocalization } from '@/data/Localization';
import { PageLink } from '@/data/Navigation';
import { useUser } from '@/data/User';
import { ID } from '@/data/types/Basic';
import { KeyboardArrowRight } from '@mui/icons-material';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	IconButton,
	Link,
	Stack,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { FC, useEffect, useState } from 'react';

export const MobileDrawerMenu: FC<{
	tree?: PageLink[];
	id: ID;
}> = ({ tree, id }) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));
	const getEspotNameFromCategoryId = (categoryId?: string) =>
		`React_${categoryId}_Menu_CategoryList`;
	const [openAccountDialog, setOpenAccountDialog] = useState(false);
	const { AccountPopper: AccountPopperNLS } = useLocalization('Header');
	const HeaderLabels = useLocalization('Header');
	const { user } = useUser();
	const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

	const welcomeMessage = isLoggedIn
		? user?.firstName
			? AccountPopperNLS.Welcome.t({ firstName: user?.firstName })
			: HeaderLabels.Actions.WelcomeNoFirstName.t()
		: AccountPopperNLS.Login.t();

	const [expanded, setExpanded] = useState<string | false>(false);

	const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
		setExpanded(newExpanded ? panel : false);
	};

	// for CDN caching -- render this on client
	useEffect(() => {
		setIsLoggedIn(() => user?.isLoggedIn ?? false);
	}, [user]);

	return (
		<>
			{tree && tree.length > 0 ? (
				<Stack direction="column" sx={{ padding: '0 20px' }}>
					{tree.map(({ label, categoryId, url }) => (
						<Accordion
							square={true}
							disableGutters={true}
							elevation={0}
							key={`${label}${url}`}
							expanded={expanded === `${label}${url}`}
							onChange={handleChange(`${label}${url}`)}
							sx={{
								borderBottom: '1px solid #D8D8D8',
								'&:first-child': {
									borderTop: 0,
								},
								'&:last-child': {
									borderBottom: 0,
								},
							}}
						>
							<AccordionSummary
								expandIcon={<KeyboardArrowRight sx={{ color: '#000' }} />}
								sx={navItemAccordionContainerSX}
							>
								<Link
									component="a"
									href={url}
									sx={{
										fontFamily: 'ProximaNova',
										fontSize: '12px',
										fontWeight: 700,
										letterSpacing: '0.6px',
										textTransform: 'uppercase',
									}}
								>
									{label}
								</Link>
							</AccordionSummary>
							<AccordionDetails sx={{ padding: 0 }}>
								<ContentRecommendation
									key={getEspotNameFromCategoryId(categoryId)}
									id={id}
									properties={{ emsName: getEspotNameFromCategoryId(categoryId) }}
								/>
							</AccordionDetails>
						</Accordion>
					))}
				</Stack>
			) : null}
			{isMobile ? (
				<Stack
					alignItems="flex-start"
					justifyContent="center"
					sx={{ marginTop: 4, marginLeft: '14px' }}
				>
					<IconButton onClick={() => setOpenAccountDialog(true)} sx={{ marginBottom: 4 }}>
						<Box
							sx={{
								marginRight: '8px',
								width: '12px',
								height: '12px',
								'.black-icon': { display: 'block', width: '12px', height: '12px' },
							}}
						>
							<ContentRecommendation
								key="AccountIcon"
								id={id}
								properties={{ emsName: 'AccountIcon' }}
							/>
						</Box>
						<Typography sx={{ color: '#000', fontSize: '12px', letterSpacing: '0.6px' }}>
							{welcomeMessage}
						</Typography>
					</IconButton>
					<MobileAccountDialog
						openAccountDialog={openAccountDialog}
						setOpenAccountDialog={setOpenAccountDialog}
					/>
				</Stack>
			) : null}
		</>
	);
};
