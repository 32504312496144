/* eslint-disable complexity */
import { SxProps } from '@mui/material';

export const wacoalHeaderContainerSX = (
	isPageScrolled: boolean,
	isHomepage: boolean,
	isBtemptd: boolean
): SxProps => ({
	zIndex: 20,
	position: 'relative',
	transition: 'background-color, .2s',
	top: 0,
	background: {
		xs: 'rgba(255, 255, 255, .85)',
		lg: isHomepage
			? isPageScrolled
				? 'rgba(255, 255, 255, .85)'
				: 'transparent linear-gradient(180deg, rgba(0,0,0,0.37) 0%, #00000000 100%)'
			: 'rgba(255, 255, 255, .85)',
	},

	'&:hover': {
		background: 'rgba(255, 255, 255, .85)',
	},
	'.header-icon-container .black-icon': {
		display: 'block',
	},
	'#storelogo': {
		mt: '0',
		ml: { lg: '29px' },
		width: isBtemptd ? '204px' : '188px',
		minWidth: { sm: isBtemptd ? '204px' : '188px' },
		height: 'auto',
	},
	...(isHomepage
		? {
				'&:hover .header-icon-container': {
					'.white-icon': {
						opacity: { lg: 0 },
					},
					'.black-icon': {
						opacity: { lg: 1 },
					},
				},
				'.header-icon-container': {
					position: { lg: 'relative' },
					'.white-icon, .black-icon': {
						display: { lg: 'block !important' },
						opacity: { lg: 1 },
						transition: { lg: 'opacity, .2s' },
					},
					'.white-icon': {
						position: { lg: 'absolute' },
						top: { lg: 0 },
						opacity: { lg: isPageScrolled ? 0 : 1 },
					},
					'.black-icon': {
						opacity: { lg: isPageScrolled ? 1 : 0 },
					},
				},
		  }
		: null),
});
