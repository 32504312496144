/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { SxProps, Theme } from '@mui/material';

export const carouselSlideSX: SxProps<Theme> = () => ({
	'& .carousel__inner-slide > div': {
		display: '-webkit-box',
		WebkitLineClamp: '3',
		WebkitBoxOrient: 'vertical',
		textOverflow: 'ellipsis',
	},
});
