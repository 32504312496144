/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { FreeShipEspot } from '@/components/content/Header/parts/WacoalMiniCart/FreeShipEspot';
import { headerMiniCartMenuSX } from '@/components/content/Header/styles/miniCart/menu';
import { headerMiniCartMoreItemsSX } from '@/components/content/Header/styles/miniCart/moreItems';
import { OrderItemTable } from '@/components/content/OrderItemTable';
import { useCart } from '@/data/Content/Cart';
import { useJSPStoreUrl } from '@/data/Content/JSPStoreUrl';
import { useStoreName } from '@/data/Content/StoreName';
import { useNextRouter } from '@/data/Content/_NextRouter';
import { useLocalization } from '@/data/Localization';
import { ID } from '@/data/types/Basic';
import { dFix } from '@/utils/floatingPoint';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { FC, useMemo } from 'react';

type Props = {
	handleToolTip: any;
	id: ID;
};

export const WacoalHeaderMiniCartDropMenu: FC<Props> = ({ handleToolTip }) => {
	const router = useNextRouter();
	const CartLabels = useLocalization('MiniCart');
	const CloseNLS = useLocalization('Close');
	const {
		orderItemsWithoutDonationItem,
		cartTotalWithoutDonationItem,
		countWithoutDonationItem,
		data: order,
		getCount,
	} = useCart();
	const locale = useMemo(() => router.locale ?? router.defaultLocale, [router]);
	const currency = order?.totalProductPriceCurrency;
	const MAX_ROWS = 10;
	const { firstNRows, firstNRowsCount } = useMemo(() => {
		const firstNRows = orderItemsWithoutDonationItem?.slice(0, MAX_ROWS);
		const firstNRowsCount = getCount(firstNRows);
		return { firstNRows, firstNRowsCount };
	}, [MAX_ROWS, getCount, orderItemsWithoutDonationItem]);
	const { createJSPStoreUrlFromViewName } = useJSPStoreUrl();
	const { isBtemptd } = useStoreName();
	const priceColor = isBtemptd ? '#6d4f6d' : '#9C304D';

	return (
		<Stack sx={headerMiniCartMenuSX}>
			<IconButton
				aria-label={CloseNLS.t()}
				onClick={handleToolTip('close')}
				sx={{ position: 'absolute', right: 0, color: '#000', zIndex: 1 }}
			>
				<CloseIcon />
			</IconButton>
			{firstNRows?.length > 0 ? (
				<Stack>
					<Box
						sx={{
							padding: '24px 15px 15px',
							borderTop: 'solid 1px #d9d9d9',
							borderBottom: 'solid 2px #d9d9d9',
						}}
					>
						<Box
							sx={{
								maxHeight: '300px',
								overflow: 'auto',
								'.MuiTableCell-root': { padding: 0, border: 0 },
								'.MuiPaper-root': { backgroundColor: 'transparent', borderRadius: 0 },
							}}
						>
							<Stack>
								<OrderItemTable
									orderItems={firstNRows}
									orderId={order?.orderId}
									readOnly={true}
									variant="mini"
								/>
								{countWithoutDonationItem > firstNRowsCount ? (
									<Typography sx={headerMiniCartMoreItemsSX} align="right">
										{CartLabels.AndNMore.t({ count: countWithoutDonationItem - firstNRowsCount })}
									</Typography>
								) : null}
							</Stack>
						</Box>
						<Stack direction="row" justifyContent="space-between" sx={{ padding: '8px 11px' }}>
							<Typography variant="body1" sx={{ fontSize: '13px' }}>
								{CartLabels.Subtotal.t({ count: countWithoutDonationItem })}
							</Typography>
							<Typography variant="body1" sx={{ fontSize: '13px', fontWeight: 700 }}>
								{Intl.NumberFormat(locale, {
									style: 'currency',
									currency,
									currencyDisplay: 'narrowSymbol',
								}).format(dFix(cartTotalWithoutDonationItem))}
							</Typography>
						</Stack>
					</Box>
					<Box sx={{ padding: '0 15px 30px 15px' }}>
						<Box
							sx={{
								textAlign: 'center',
								margin: '24px 0',
								color: priceColor,
								textDecoration: 'underline',
								fontSize: '16px',
								textTransform: 'uppercase',
							}}
						>
							<FreeShipEspot count={countWithoutDonationItem} />
						</Box>
						<Button
							component="a"
							variant="contained"
							href={createJSPStoreUrlFromViewName(
								'RESTOrderCalculate',
								'&calculationUsageId=-1&doConfigurationValidation=Y&updatePrices=1&orderId=.&errorViewName=AjaxOrderItemDisplayView&URL=/webapp/wcs/stores/servlet/AjaxOrderItemDisplayView'
							)}
							sx={{ width: '100%', fontSize: '19px', fontWeight: 400, height: '60px' }}
						>
							{CartLabels.ProceedToCheckout.t()}
						</Button>
					</Box>
				</Stack>
			) : (
				<Stack>
					<Stack
						justifyContent="flex-start"
						width="100%"
						sx={{ borderBottom: '2px solid #d9d9d9', padding: '20px 20px 15px 20px' }}
					>
						<Typography variant="body1" sx={{ fontSize: '12px' }}>
							{CartLabels.Empty.t()}
						</Typography>
					</Stack>
					<Box>
						<FreeShipEspot count={countWithoutDonationItem} />
					</Box>
					<Box sx={{ margin: '20px 20px 30px 20px' }}>
						<Button
							component="a"
							variant="contained"
							href={createJSPStoreUrlFromViewName(
								'RESTOrderCalculate',
								'&calculationUsageId=-1&doConfigurationValidation=Y&updatePrices=1&orderId=.&errorViewName=AjaxOrderItemDisplayView&URL=/webapp/wcs/stores/servlet/AjaxOrderItemDisplayView'
							)}
							sx={{ width: '100%', fontSize: '19px', fontWeight: 400, height: '60px' }}
						>
							{CartLabels.ProceedToCheckout.t()}
						</Button>
					</Box>
				</Stack>
			)}
		</Stack>
	);
};
