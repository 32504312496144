import { SxProps } from '@mui/material';

export const wacoalHeaderNavBarItemSX = (
	isPageScrolled: boolean,
	isWacoal: boolean,
	isHomepage: boolean,
	isActive: boolean
): SxProps => ({
	'&.MuiButton-text': {
		p: isWacoal ? 1 : '8px 18px',
		backgroundColor: 'transparent',
		transition: 'color .2s',
		height: '100%',
		lineHeight: '15px',
		color: {
			xs: '#000',
			lg: isHomepage ? (isPageScrolled ? '#000' : 'white') : '#000',
		},
		'header:hover &': {
			color: '#000',
		},
		'&:hover': {
			backgroundColor: 'transparent',
			color: '#000',
			'.MuiBox-root': {
				borderBottom: '2px solid #252525',
				marginBottom: '-2px',
			},
		},
		...(isActive
			? {
					'.MuiBox-root': {
						borderBottom: '2px solid #252525',
						marginBottom: '-2px',
					},
			  }
			: null),
		fontSize: isWacoal ? 12 : 15,
		textTransform: 'uppercase',
		fontWeight: isWacoal ? 600 : 700,
		fontFamily: isWacoal ? 'ProximaNova' : 'Roboto',
		letterSpacing: '0.96px',
		borderRadius: 0,
		'&:is(a):not([href])': {
			cursor: 'default',
		},
	},
});
