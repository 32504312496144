/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { SxProps } from '@mui/material';

export const headerMiniCartDropMenuSX: SxProps = {
	borderRadius: 0,
	maxWidth: 'none',
	boxShadow: 0,
	margin: '0 !important',
	backgroundColor: '#F9F9F9',
	padding: 0,
	color: '#252525',
	letterSpacing: '0.25px',
	border: '1px solid #d9d9d9',
};
