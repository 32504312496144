/* eslint-disable complexity */
import { SxProps } from '@mui/material';

export const wacoalHeaderSearchSX = ({
	isPageScrolled,
	isHomepage,
	isWacoal,
	isBtemptd,
}: {
	isPageScrolled?: boolean;
	isHomepage?: boolean;
	isWacoal: boolean;
	isBtemptd: boolean;
}): SxProps => ({
	width: { lg: '216px' },
	marginRight: { lg: isBtemptd ? '18px' : 0 },
	display: { lg: 'block', md: 'none', sm: 'none', xs: 'none' },
	/* clears the 'X' from Chrome */
	'input[type="search"]::-webkit-search-decoration, input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-results-button,input[type = "search"]::-webkit-search-results-decoration':
		{ display: 'none' },

	'.MuiOutlinedInput-root': {
		fieldset: {
			borderRadius: isBtemptd ? '2px' : 0,
		},
		backgroundColor: 'transparent',
		transition: 'color .2s',
		color: isHomepage ? (isPageScrolled ? 'black' : 'white') : 'black',
		'header:hover &': {
			color: 'black',
		},
		height: { xs: '51px', lg: isBtemptd ? '51px' : '39px' },
		fontFamily: isBtemptd ? 'Roboto' : 'ProximaNova',
		fontWeight: isBtemptd ? 300 : 400,
		letterSpacing: isBtemptd ? '0.35px' : 0,
		'&.MuiInputBase-sizeSmall': {
			paddingLeft: 0,
		},
	},
	'.MuiInputBase-input.MuiInputBase-inputSizeSmall.MuiAutocomplete-input': {
		paddingLeft: isBtemptd ? { xs: '20px', lg: 0 } : '5px',
		fontSize: isBtemptd ? '14px' : '16px',
	},
	'.MuiOutlinedInput-notchedOutline': {
		transition: 'border-color .2s, border-width .2s',
		borderWidth: '1px',
	},

	...(isWacoal
		? {
				'.MuiInputAdornment-root .MuiIconButton-root': {
					transition: 'color .2s',
					marginRight: '-6px',
					color: isHomepage ? (isPageScrolled ? 'black' : 'white') : 'black',
					'header:hover &': {
						color: 'black',
					},
				},
				'.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-notchedOutline':
					{
						borderColor: isHomepage ? (isPageScrolled ? '#A8A8A8' : 'white') : '#A8A8A8',
					},
				'header:hover & .MuiOutlinedInput-notchedOutline': {
					borderColor: '#A8A8A8',
				},
				'header:hover & .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
					borderColor: '#A8A8A8',
				},
				'&.Mui-focused:not(.Mui-error) .MuiOutlinedInput-root:not(.Mui-error) fieldset.MuiOutlinedInput-notchedOutline':
					{
						borderColor: isHomepage ? (isPageScrolled ? '#A8A8A8' : 'white') : '#A8A8A8',
					},
				'header:hover &.Mui-focused:not(.Mui-error) .MuiOutlinedInput-root:not(.Mui-error) fieldset.MuiOutlinedInput-notchedOutline':
					{
						borderColor: '#A8A8A8',
					},
		  }
		: {
				'.MuiInputAdornment-root .MuiIconButton-root': {
					marginLeft: '12px',
					paddingRight: '4px',
				},
				'header:hover & .MuiOutlinedInput-notchedOutline': {
					borderColor: '#000',
				},
				'header:hover & .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
					borderColor: '#000',
				},
				'.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-notchedOutline':
					{
						borderColor: isHomepage ? (isPageScrolled ? '#000' : 'white') : '#000',
					},
		  }),

	'.MuiOutlinedInput-input': {
		'&::-webkit-input-placeholder': {
			opacity: 0.8,
		},
	},
});
