import { Linkable } from '@/components/blocks/Linkable';
import { ContentRecommendation } from '@/components/content/ContentRecommendation';
import { HeaderBanner } from '@/components/content/Header/parts/Banner';
import { MobileSearchDropMenu } from '@/components/content/Header/parts/MobileSearchDropMenu';
import { HeaderMobileToggleButton } from '@/components/content/Header/parts/MobileToggleButton';
import { WacoalHeaderAccount } from '@/components/content/Header/parts/WacoalAccount';
import { WacoalHeaderMiniCart } from '@/components/content/Header/parts/WacoalMiniCart';
import { WacoalHeaderMobileDrawer } from '@/components/content/Header/parts/WacoalMobileDrawer';
import { WacoalHeaderNavBar } from '@/components/content/Header/parts/WacoalNavBar';
import { WacoalHeaderSearch } from '@/components/content/Header/parts/WacoalSearch';
import { wacoalHeaderContainerSX } from '@/components/content/Header/styles/wacoalContainer';
import { wacoalNavigationSX } from '@/components/content/Header/styles/wacoalNavigation';
import { useStoreName } from '@/data/Content/StoreName';
import { useSearchNavigation } from '@/data/Content/WacoalSearchNavigation';
import { usePageDataFromId } from '@/data/_PageDataFromId';
import { ContentProvider } from '@/data/context/content';
import { ID } from '@/data/types/Basic';
import { Box, Container, Stack, useMediaQuery, useTheme } from '@mui/material';
import throttle from 'lodash/throttle';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';

export const Header: FC<{
	id: ID;
}> = ({ id }) => {
	const { isHomepage } = usePageDataFromId();
	const theme = useTheme();
	const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));
	const { isWacoal, isBtemptd } = useStoreName();
	const [openDesktopMenuId, setOpenDesktopMenuId] = useState<string | undefined>();
	const [drawerOpen, setDrawerOpen] = useState(false);
	const toggleDrawer = useCallback(
		(open?: boolean) => () => {
			setDrawerOpen(open !== undefined ? open : (current) => !current);
		},
		[]
	);

	/** https://gist.github.com/iDVB/a041da210605f05e0b36ac03ed403c00 */
	const [isPageScrolled, setIsPageScrolled] = useState(false);
	useEffect(() => {
		setIsPageScrolled(
			isHomepage
				? typeof window !== 'undefined'
					? window.scrollY > 0 || openDesktopMenuId !== undefined
					: false
				: false
		);
	}, [isHomepage, openDesktopMenuId]);

	const throttledCallback = useMemo(
		() =>
			throttle(() => {
				setIsPageScrolled(window?.scrollY > 0 || openDesktopMenuId !== undefined);
			}, 20),
		[openDesktopMenuId]
	);
	useEffect(() => {
		if (isHomepage) {
			window.addEventListener('scroll', throttledCallback);
		}
		return () => {
			window.removeEventListener('scroll', throttledCallback);
		};
	}, [throttledCallback, isHomepage]);

	const { searchValue, suggest, onInputChange, onSubmit, inputValue, clearSearchTerm } =
		useSearchNavigation();

	return (
		<Box component="header" sx={wacoalHeaderContainerSX(isPageScrolled, isHomepage, isBtemptd)}>
			<HeaderBanner id={id} />
			<Container sx={{ maxWidth: { lg: 'none' }, padding: { xs: 0 } }}>
				<Stack
					direction="row"
					alignItems="center"
					justifyContent="space-between"
					height={{ xs: '67px', lg: '76px' }}
				>
					<HeaderMobileToggleButton
						toggleDrawer={toggleDrawer}
						open={drawerOpen}
						isPageScrolled={isPageScrolled}
						isHomepage={isHomepage}
					/>
					<WacoalHeaderMobileDrawer toggleDrawer={toggleDrawer} open={drawerOpen} id={id} />
					<Stack
						component={Linkable}
						href="/"
						direction="row"
						alignItems="center"
						spacing={{ lg: 2 }}
					>
						<ContentRecommendation key="MainLogo" id={id} properties={{ emsName: 'MainLogo' }} />
					</Stack>
					<Stack height="100%" width="100%" sx={wacoalNavigationSX}>
						<WacoalHeaderNavBar
							openMenuId={openDesktopMenuId}
							setOpenMenuId={setOpenDesktopMenuId}
							isPageScrolled={isPageScrolled}
							isHomepage={isHomepage}
							isBtemptd={isBtemptd}
							id={id}
						/>
					</Stack>
					<Stack
						direction="row"
						alignItems="center"
						justifyContent="flex-end"
						spacing={{ xs: '4px', lg: '10px' }}
						sx={{ flex: { xs: 1, lg: 'none' } }}
					>
						<ContentProvider
							value={{ searchValue, suggest, onInputChange, onSubmit, inputValue, clearSearchTerm }}
						>
							<WacoalHeaderSearch
								mobile={false}
								isPageScrolled={isPageScrolled}
								isHomepage={isHomepage}
								isWacoal={isWacoal}
								isBtemptd={isBtemptd}
								id={id}
							/>

							{!isDesktop ? (
								<MobileSearchDropMenu isWacoal={isWacoal} isBtemptd={isBtemptd} id={id} />
							) : null}
						</ContentProvider>
						{!isMobile ? (
							<WacoalHeaderAccount
								mobileBreakpoint="md"
								isPageScrolled={isPageScrolled}
								isHomepage={isHomepage}
								id={id}
							/>
						) : null}
						<WacoalHeaderMiniCart
							mobileBreakpoint="md"
							isPageScrolled={isPageScrolled}
							isHomepage={isHomepage}
							isBtemptd={isBtemptd}
							id={id}
						/>
					</Stack>
				</Stack>
			</Container>
		</Box>
	);
};
