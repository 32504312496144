import { SxProps } from '@mui/material';

export const wacoalHeaderMiniCartBadgeSX = (
	isPageScrolled: boolean,
	isHomepage: boolean
): SxProps => ({
	width: '24px',
	height: '24px',
	alignItems: 'center',
	'.MuiBadge-badge': {
		right: '4px',
		top: 'auto',
		bottom: '-11px',
		color: { xs: 'white', lg: isHomepage ? (isPageScrolled ? 'white' : 'black') : 'white' },
		backgroundColor: {
			xs: 'black',
			lg: isHomepage ? (isPageScrolled ? 'black' : 'white') : 'black',
		},
		'header:hover &': {
			color: 'white',
			backgroundColor: 'black',
		},
		padding: '1px 4px',
		minWidth: '13px',
		height: '13px',
		fontSize: '8px',
		fontWeight: 600,
		transition: 'background .2s, color .2s',
	},
	'.MuiSvgIcon-root': {
		transition: 'color .2s',
		color: { xs: 'white', lg: isHomepage ? (isPageScrolled ? 'black' : 'white') : 'black' },
	},
});
