import { ContentRecommendation } from '@/components/content/ContentRecommendation';
import { useStoreName } from '@/data/Content/StoreName';
import { useSwitchStore } from '@/data/Content/SwitchStore';
import { useLocalization } from '@/data/Localization';
import { ID } from '@/data/types/Basic';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent, DialogTitle, IconButton, Stack } from '@mui/material';
import { FC, useState } from 'react';

export const WacoalHeaderStoreSwitch: FC<{
	id: ID;
}> = ({ id }) => {
	const [open, setOpen] = useState(false);
	const { wacoalAmericaUrl, wacoalCanadaUrl } = useSwitchStore();
	const { StoreSwitch: StoreSwitchNLS } = useLocalization('Header');
	const CloseNLS = useLocalization('Close');
	const { isWacoal } = useStoreName();

	return isWacoal ? (
		<>
			<IconButton
				aria-label={StoreSwitchNLS.ChangeCountry.t()}
				sx={{
					padding: '9px 13px',
					marginRight: { md: '3px', lg: '5px', xl: '24px' },
					width: '52px',
				}}
				onClick={() => setOpen(true)}
			>
				<Stack alignItems="center" direction="row" spacing={1}>
					<ContentRecommendation key="StoreFlag" id={id} properties={{ emsName: 'StoreFlag' }} />
				</Stack>
			</IconButton>
			<Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="switch-store-dialog">
				<IconButton
					aria-label={CloseNLS.t()}
					onClick={() => setOpen(false)}
					sx={{ position: 'absolute', right: 0, color: '#000', zIndex: 1 }}
				>
					<CloseIcon />
				</IconButton>
				<DialogContent>
					<Stack sx={{ paddingTop: 1 }}>
						<DialogTitle
							component="h3"
							id="switch-store-dialog"
							sx={{
								textTransform: 'uppercase',
								textAlign: 'center',
								fontSize: '18px',
								fontWeight: 600,
							}}
						>
							{StoreSwitchNLS.PleaseSelectACountry.t()}
						</DialogTitle>
						<Stack direction="row" justifyContent="center">
							<IconButton
								component="a"
								href={wacoalAmericaUrl}
								aria-label={StoreSwitchNLS.WacoalAmerica.t()}
							>
								<ContentRecommendation
									key="USStoreFlag"
									id={id}
									properties={{ emsName: 'USStoreFlag' }}
								/>
							</IconButton>
							<IconButton
								component="a"
								href={wacoalCanadaUrl}
								aria-label={StoreSwitchNLS.WacoalCanada.t()}
							>
								<ContentRecommendation
									key="CanadaStoreFlag"
									id={id}
									properties={{ emsName: 'CanadaStoreFlag' }}
								/>
							</IconButton>
						</Stack>
					</Stack>
				</DialogContent>
			</Dialog>
		</>
	) : null;
};
