/*
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited 2023.
 */

// replaced email regex with version from JSP due to iOS Safari lack of support for regex lookback
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Regular_expressions/Lookbehind_assertion

export const REGEX = {
	/* EMAIL: /^([\w.%+\-]+)@((?!-)[\w\-]+(?<!-)\.)+([a-z]{2,})$/i, */
	EMAIL:
		/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
	PHONE: /^[\-+\(\) ]*[0-9][\-+\(\) 0-9]*$/,
	/* NICKNAME_ALPHA_NUMERIC_SPECIAL_CHAR: /^(?!\s)[a-zA-Z0-9 ]+(?<!\s)$/,*/
	NICKNAME_ALPHA_NUMERIC_SPECIAL_CHAR: /^[ A-Za-z0-9]*$/,
	CARD_NUMBER_MASK: /[0-9](?=([0-9]{4}))/g,
	ONLY_STRING: /[^a-zA-Z]/g,
};
