import { ContentRecommendation } from '@/components/content/ContentRecommendation';
import { PromotionSlider } from '@/components/content/Header/parts/HeaderPromotion/PromotionSlider';
import { WacoalHeaderStoreSwitch } from '@/components/content/Header/parts/WacoalStoreSwitch';
import { headerBannerContainerSX } from '@/components/content/Header/styles/banner/container';
import { headerBannerLogoSX } from '@/components/content/Header/styles/banner/logo';
import { useStoreName } from '@/data/Content/StoreName';
import { ID } from '@/data/types/Basic';
import { combineSX } from '@/utils/combineSX';
import CloseIcon from '@mui/icons-material/Close';
import {
	Box,
	Container,
	Dialog,
	DialogContent,
	IconButton,
	Stack,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';

export const HeaderBanner: FC<{
	id: ID;
}> = ({ id }) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
	const { isWacoalCanada, isBtemptd, isWacoalAmerica } = useStoreName();

	const [showDialog, setShowDialog] = useState(false);

	const handleClick = useCallback((event: any) => {
		if (event?.target?.id === 'header-banner-popup-button') {
			event.preventDefault();
			setShowDialog(true);
		}
	}, []);

	useEffect(() => {
		window.addEventListener('click', handleClick);
		return () => {
			window.removeEventListener('click', handleClick);
		};
	}, [handleClick]);

	return (
		<Box sx={headerBannerContainerSX}>
			<Container
				sx={{
					display: 'flex',
					alignItems: 'center',
					padding: { xs: 0, lg: 0 },
					maxWidth: { lg: 'none' },
				}}
			>
				<Box
					sx={{
						display: 'flex',
						flex: 1,
						justifyContent: 'center',
						height: '100%',
						maxWidth: '230px',
					}}
				>
					{!isMobile ? (
						<Stack
							direction="row"
							sx={{ height: '100%', marginRight: 'auto', marginLeft: { lg: '14px', xl: '32px' } }}
						>
							<Box
								sx={combineSX([
									headerBannerLogoSX,
									{ backgroundColor: isWacoalAmerica ? 'white' : 'transparent' },
								])}
							>
								<ContentRecommendation
									key="React_wacoal_StoreLogo_Content"
									id={id}
									properties={{ emsName: 'React_wacoal_StoreLogo_Content' }}
								/>
							</Box>
							{!isWacoalCanada ? (
								<Box
									sx={combineSX([
										headerBannerLogoSX,
										{ backgroundColor: isBtemptd ? 'white' : 'transparent' },
									])}
								>
									<ContentRecommendation
										key="React_btemptd_StoreLogo_Content"
										id={id}
										properties={{ emsName: 'React_btemptd_StoreLogo_Content' }}
									/>
								</Box>
							) : null}
						</Stack>
					) : null}
				</Box>
				<Box
					sx={{ display: 'flex', justifyContent: 'center', a: { color: 'white' }, width: '100%' }}
				>
					<PromotionSlider
						key="ReactHeaderBanner"
						id={id}
						properties={{ emsName: 'ReactHeaderBanner' }}
					/>
				</Box>
				<Box
					sx={{
						display: 'flex',
						flex: 1,
						justifyContent: 'center',
						maxWidth: '70px',
						paddingLeft: isBtemptd ? { lg: '13px', md: '0' } : '0',
					}}
				>
					<Box sx={{ marginLeft: 'auto' }}>
						<WacoalHeaderStoreSwitch id={id} />
					</Box>
				</Box>
			</Container>

			<Dialog
				open={showDialog}
				onClose={() => setShowDialog(false)}
				aria-labelledby="header-popup-label"
			>
				<IconButton
					aria-label="close"
					onClick={() => setShowDialog(false)}
					sx={{ position: 'absolute', right: 0, color: '#000', zIndex: 1 }}
				>
					<CloseIcon />
				</IconButton>
				<DialogContent>
					<ContentRecommendation
						key="ReactHeaderPopup"
						id={id}
						properties={{ emsName: 'ReactHeaderPopup' }}
					/>
				</DialogContent>
			</Dialog>
		</Box>
	);
};
