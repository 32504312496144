import { SxProps } from '@mui/material';

export const wacoalHeaderAccountContainerSX = (
	isPageScrolled: boolean,
	isHomepage: boolean
): SxProps => ({
	position: 'relative',
	'.MuiSvgIcon-root': {
		color: { xs: 'black', lg: isHomepage ? (isPageScrolled ? 'black' : 'white') : 'black' },
		'header:hover &': {
			color: 'black',
		},
	},
});
