/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { ContentRecommendation } from '@/components/content/ContentRecommendation';
import { WacoalHeaderMiniCartDropMenu } from '@/components/content/Header/parts/WacoalMiniCartDropMenu';
import { headerMiniCartContainerSX } from '@/components/content/Header/styles/miniCart/container';
import { headerMiniCartDropMenuSX } from '@/components/content/Header/styles/miniCart/dropMenu';
import { wacoalHeaderMiniCartBadgeSX } from '@/components/content/Header/styles/miniCart/wacoalBadge';
import { wacoalHeaderMiniCartLinkSX } from '@/components/content/Header/styles/miniCart/wacoalLink';
import { useCart } from '@/data/Content/Cart';
import { useNotifications } from '@/data/Content/Notifications';
import { useNextRouter } from '@/data/Content/_NextRouter';
import { useLocalization } from '@/data/Localization';
import { useUser } from '@/data/User';
import { ID } from '@/data/types/Basic';
import { useThemeSettings } from '@/styles/theme';
import { Switch } from '@/utils/switch';
import {
	Badge,
	Breakpoint,
	Button,
	ClickAwayListener,
	IconButton,
	Stack,
	Tooltip,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { FC, useCallback, useEffect, useRef, useState } from 'react';

type Props = {
	mobileBreakpoint: Breakpoint;
	isPageScrolled?: boolean;
	isHomepage?: boolean;
	isBtemptd: boolean;
	id: ID;
};

export const WacoalHeaderMiniCart: FC<Props> = ({
	mobileBreakpoint = 'sm',
	isPageScrolled = false,
	isHomepage = false,
	isBtemptd,
	id,
}) => {
	const router = useNextRouter();
	const CartLabels = useLocalization('MiniCart');
	const { message } = useNotifications();
	const theme = useTheme();
	const { getAdditive } = useThemeSettings();
	const { user: { userId } = {} } = useUser();
	const { countWithoutDonationItem: itemCount, mutateCart } = useCart();
	const isMobile = useMediaQuery(theme.breakpoints.down(mobileBreakpoint));
	const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
	const [open, setOpen] = useState(false);
	const initialized = useRef<boolean>(false);
	const [count, setCount] = useState<number>(0);

	const handleToolTip = useCallback(
		(action?: string) => () =>
			setOpen((open) =>
				Switch(action)
					.case('open', () => true)
					.case('close', () => false)
					.case('toggle', () => !open)
					.defaultTo(() => !open)
			),
		[]
	);

	useEffect(() => {
		if (message.isAddToCart) {
			setOpen(true);
		}
	}, [message.isAddToCart]);
	useEffect(() => {
		if (!router.asPath) return;
		handleToolTip('close')();
	}, [handleToolTip, router.asPath]);

	useEffect(() => {
		if (initialized.current) {
			mutateCart();
		} else {
			initialized.current = true;
		}
	}, [mutateCart, userId]);

	// for CDN caching -- render this on client
	useEffect(() => {
		setCount(() => itemCount);
	}, [itemCount, isHomepage]);

	/* useEffect(() => {
		window.addEventListener('touchmove', handleToolTip('close'));
		return () => window.removeEventListener('touchmove', handleToolTip('close'));
	}, [handleToolTip]);*/

	return (
		<ClickAwayListener onClickAway={handleToolTip('close')}>
			<Stack sx={headerMiniCartContainerSX}>
				<Tooltip
					placement="bottom"
					open={open}
					onClose={handleToolTip('close')}
					disableFocusListener
					disableHoverListener
					disableTouchListener
					componentsProps={{
						popper: {
							sx: {
								width: { xs: '100%', md: '360px' },
								maxHeight: { xs: 'calc(100vh - 117px)', lg: 'calc(100vh - 120px)' },
								overflow: 'auto',
							},
						},
						tooltip: {
							sx: headerMiniCartDropMenuSX,
						},
					}}
					PopperProps={{
						modifiers: [{ name: 'offset', options: { offset: isTablet ? [0, 23] : [0, 27] } }],
					}}
					title={<WacoalHeaderMiniCartDropMenu handleToolTip={handleToolTip} id={id} />}
				>
					<IconButton
						onClick={handleToolTip('toggle')}
						aria-label={CartLabels.Items.t({ count })}
						sx={wacoalHeaderMiniCartLinkSX(isBtemptd)}
					>
						<Stack alignItems="center">
							<Badge
								badgeContent={count}
								color="secondary"
								sx={wacoalHeaderMiniCartBadgeSX(isPageScrolled, isHomepage)}
							>
								<ContentRecommendation
									key="MiniShopCart"
									id={id}
									properties={{ emsName: 'MiniShopCart' }}
								/>
							</Badge>
						</Stack>
					</IconButton>
				</Tooltip>
				{isMobile === false ? (
					<Button sx={getAdditive('coverTapTarget')} onClick={handleToolTip()}>
						{CartLabels.Title.t()}
					</Button>
				) : null}
			</Stack>
		</ClickAwayListener>
	);
};
