/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { SxProps, Theme } from '@mui/material';

export const promotionSliderSX: SxProps<Theme> = (theme) => ({
	width: '100%',
	padding: 0,
	'& .carousel__inner-slide': {
		display: 'flex',
		verticalAlign: 'middle',
		alignItems: 'center',
	},
	'& .carousel__back-button, & .carousel__next-button': {
		height: '32px !important',
		'&:hover': {
			border: '1px solid transparent !important',
		},
		'& svg': {
			color: '#fff',
			fontSize: '27px',
		},
	},
});
